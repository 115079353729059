import { FC } from 'react';

import { countries } from './countries';
import { StyledIcon } from './countryIcon.styles';
import { CountryIconProps, ItemProps } from './countryIcon.types';

export const CountryIconV2: FC<CountryIconProps> = ({ name, width, height, borderRadius }) => {
  const country = countries.find((item: ItemProps) => item.label === name);

  if (!country) {
    return null;
  }

  return <StyledIcon $width={width} $height={height} src={country.iconSrc} $borderRadius={borderRadius} />;
};
