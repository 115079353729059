import { IconProps } from './iconProps';

export const MessageTextIcon: React.FC<IconProps> = ({ strokeColor = 'black' }) => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.20039 6.20002H14.6004M6.20039 11H11.0004M10.6873 15.5913L5.67865 20.6V15.5913H3.80039C2.47491 15.5913 1.40039 14.5168 1.40039 13.1913V3.80003C1.40039 2.47454 2.47491 1.40002 3.80039 1.40002H18.2004C19.5259 1.40002 20.6004 2.47454 20.6004 3.80002V13.1913C20.6004 14.5168 19.5259 15.5913 18.2004 15.5913H10.6873Z"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
