import styled from 'styled-components';

export const BlogThumbnail = styled.img`
  object-fit: cover;
`;

export const BlogThumbnailWrapper = styled.div`
  flex-shrink: 0;
  width: 13.125rem; 
  height='9rem';
  border-radius: ${({ theme }) => theme.v2.radius.smaller};
`;

export const BlogTextWrap = styled.div<{ $linesNumber?: number }>`
  overflow: hidden;
  > span {
    display: -webkit-box;
    -webkit-line-clamp: ${({ $linesNumber }) => $linesNumber || 3};
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const TextNoShrink = styled.div`
  flex-shrink: 0;
`;
