import { ItemProps } from './countryIcon.types';

export const countries: ItemProps[] = [
  {
    value: 'e36f94e9-9da1-40d0-9aa2-33abdcb69c02',
    label: 'Switzerland',
    iconSrc: '/assets/flags/ch.svg',
  },
  {
    value: '9563e542-2429-47c1-b867-dd4d702511f9',
    label: 'Afghanistan',
    iconSrc: '/assets/flags/af.svg',
  },
  {
    value: '25b05d65-d2dc-4d55-b12d-5c1c8e816c13',
    label: 'Aland Islands',
    iconSrc: '/assets/flags/ax.svg',
  },
  {
    value: 'f7d14dea-045d-44a0-8845-4174cca8351b',
    label: 'Albania',
    iconSrc: '/assets/flags/al.svg',
  },
  {
    value: 'f98b7992-0892-4c5f-8fb3-a3fc63717557',
    label: 'Algeria',
    iconSrc: '/assets/flags/dz.svg',
  },
  {
    value: '76e3e706-d24d-458f-ba46-8e857d171c64',
    label: 'American Samoa',
    iconSrc: '/assets/flags/as.svg',
  },
  {
    value: 'eb365996-8e20-4acd-b69b-6853692beb20',
    label: 'Andorra',
    iconSrc: '/assets/flags/ad.svg',
  },
  {
    value: '8bf71744-b9bf-4503-922f-c8b24d5792ef',
    label: 'Angola',
    iconSrc: '/assets/flags/ao.svg',
  },
  {
    value: '93bcab75-a419-4991-8233-f258f940e7c8',
    label: 'Anguilla',
    iconSrc: '/assets/flags/ai.svg',
  },
  {
    value: '0c6e9842-e223-4bc1-8241-2109787c2a3d',
    label: 'Antarctica',
    iconSrc: '/assets/flags/aq.svg',
  },
  {
    value: '9d1b1425-e555-4ba2-b18c-0e36754dbb34',
    label: 'Antigua and Barbuda',
    iconSrc: '/assets/flags/ag.svg',
  },
  {
    value: '0b46fe07-8eb4-4177-b565-714b580d2140',
    label: 'Argentina',
    iconSrc: '/assets/flags/ar.svg',
  },
  {
    value: 'dd56eb35-14e9-499e-8056-6dc9d1a2bab8',
    label: 'Armenia',
    iconSrc: '/assets/flags/am.svg',
  },
  {
    value: 'd2ca85d1-827a-4df4-849f-863c85dfc88a',
    label: 'Aruba',
    iconSrc: '/assets/flags/aw.svg',
  },
  {
    value: '72f4cb25-1280-46f7-8c66-076e48c862df',
    label: 'Australia',
    iconSrc: '/assets/flags/au.svg',
  },
  {
    value: '97b39d67-bc8c-485a-8ab6-d14c15933964',
    label: 'Austria',
    iconSrc: '/assets/flags/at.svg',
  },
  {
    value: '0a4bcda0-ca65-4f42-bc37-6f6ea25a8b2c',
    label: 'Azerbaijan',
    iconSrc: '/assets/flags/az.svg',
  },
  {
    value: '9730c517-ba91-4504-acea-62cf68febd64',
    label: 'Bahamas',
    iconSrc: '/assets/flags/bs.svg',
  },
  {
    value: '1e41d792-2d2f-4f58-b8b6-be6f6dce2ffc',
    label: 'Bahrain',
    iconSrc: '/assets/flags/bh.svg',
  },
  {
    value: '453ad732-9177-4992-b106-bbb52cccf352',
    label: 'Bangladesh',
    iconSrc: '/assets/flags/bd.svg',
  },
  {
    value: 'e9a78c67-7d64-4dca-948e-d8fa31d136a3',
    label: 'Barbados',
    iconSrc: '/assets/flags/bb.svg',
  },
  {
    value: '74e90eae-e78f-409c-ad28-77e745c42369',
    label: 'Belarus',
    iconSrc: '/assets/flags/by.svg',
  },
  {
    value: '5239aed1-4b85-436e-adc8-89391196678c',
    label: 'Belgium',
    iconSrc: '/assets/flags/be.svg',
  },
  {
    value: '745aa2db-5d8b-4bf9-98b0-2e5b726158c7',
    label: 'Belize',
    iconSrc: '/assets/flags/bz.svg',
  },
  {
    value: '76357a19-f43a-4c1c-b665-94cb053680cd',
    label: 'Benin',
    iconSrc: '/assets/flags/bj.svg',
  },
  {
    value: '4ec952f3-f49c-4deb-affe-727f7930baeb',
    label: 'Bermuda',
    iconSrc: '/assets/flags/bm.svg',
  },
  {
    value: '05802a3e-c4a0-42bd-a1bd-2a5466e5c737',
    label: 'Bhutan',
    iconSrc: '/assets/flags/bt.svg',
  },
  {
    value: '65d36a74-0bfa-4c05-b92b-dbfe4d8e91ef',
    label: 'Bolivia',
    iconSrc: '/assets/flags/bo.svg',
  },
  {
    value: '4fa17853-c078-4848-871a-52e320b59c7b',
    label: 'Bonaire, Sint Eustatius and Saba',
    iconSrc: '/assets/flags/bq.svg',
  },
  {
    value: '457f564d-e5fa-494f-9eac-2c4fa195eb28',
    label: 'Bosnia and Herzegovina',
    iconSrc: '/assets/flags/ba.svg',
  },
  {
    value: '3e086ee4-b84a-4223-a9e5-6eef9ef8bf4c',
    label: 'Botswana',
    iconSrc: '/assets/flags/bw.svg',
  },
  {
    value: '78a2dcf9-2fc2-43f6-9895-1de436707d82',
    label: 'Bouvet Island',
    iconSrc: '/assets/flags/bv.svg',
  },
  {
    value: 'b74c21e7-47ce-4bc2-a14b-6d394dd60bb4',
    label: 'Brazil',
    iconSrc: '/assets/flags/br.svg',
  },
  {
    value: '44b04d58-f9d1-488f-8239-8c6c5a976545',
    label: 'British Indian Ocean Territory',
    iconSrc: '/assets/flags/io.svg',
  },
  {
    value: '7d075023-f729-4028-8c24-40bc417d6db8',
    label: 'Brunei Darussalam',
    iconSrc: '/assets/flags/bn.svg',
  },
  {
    value: 'f3de29e8-fd95-4780-a0c7-3673a9ae9638',
    label: 'Bulgaria',
    iconSrc: '/assets/flags/bg.svg',
  },
  {
    value: '556ef8b1-545d-4ea4-af35-cd3b4e5cff8e',
    label: 'Burkina Faso',
    iconSrc: '/assets/flags/bf.svg',
  },
  {
    value: 'a2ce7ac9-2874-4496-adbf-15f3ccca90ea',
    label: 'Burundi',
    iconSrc: '/assets/flags/bi.svg',
  },
  {
    value: 'b591f293-16a7-4d8a-9dc0-10766c0e631a',
    label: 'Cabo Verde',
    iconSrc: '/assets/flags/cv.svg',
  },
  {
    value: 'e461edfa-f91c-482f-9b7e-37fb3c88c885',
    label: 'Cambodia',
    iconSrc: '/assets/flags/kh.svg',
  },
  {
    value: '8fc6df21-2fd1-4993-a5e7-35ddd656020d',
    label: 'Cameroon',
    iconSrc: '/assets/flags/cm.svg',
  },
  {
    value: '11e490f7-9033-42f3-89f7-b31ccbec204c',
    label: 'Canada',
    iconSrc: '/assets/flags/ca.svg',
  },
  {
    value: '99acde54-3a26-48de-afdc-b550e1dc0506',
    label: 'Cayman Islands',
    iconSrc: '/assets/flags/ky.svg',
  },
  {
    value: '9575b31e-51c1-479f-91ae-d9596740893a',
    label: 'Central African Republic',
    iconSrc: '/assets/flags/cf.svg',
  },
  {
    value: '062f994d-0fc5-468b-9345-90168513c048',
    label: 'Chad',
    iconSrc: '/assets/flags/td.svg',
  },
  {
    value: '34e80f07-e7fb-4397-a68f-aa1eb448cf0e',
    label: 'Chile',
    iconSrc: '/assets/flags/cl.svg',
  },
  {
    value: '31bfff69-f1eb-4701-b67b-d214c5f2398d',
    label: 'China',
    iconSrc: '/assets/flags/cn.svg',
  },
  {
    value: '14d44c9c-ee7a-4a1d-84b2-f78605c79ae2',
    label: 'Christmas Island',
    iconSrc: '/assets/flags/cx.svg',
  },
  {
    value: 'b49c8b65-47c9-47d4-b888-566f03f9cfb7',
    label: 'Cocos (Keeling) Islands',
    iconSrc: '/assets/flags/cc.svg',
  },
  {
    value: '0e93c82a-8b80-481f-a1b9-ca9479b29efd',
    label: 'Colombia',
    iconSrc: '/assets/flags/co.svg',
  },
  {
    value: 'ef0612a8-7b49-4a51-a629-a4acad327b13',
    label: 'Comoros',
    iconSrc: '/assets/flags/km.svg',
  },
  {
    value: '35d32870-3335-41b9-85f7-71cdcf33612c',
    label: 'Congo (Democratic Republic of the)',
    iconSrc: '/assets/flags/cd.svg',
  },
  {
    value: '2da0379b-c68b-4ff0-8dd6-9050ff0479db',
    label: 'Congo (Republic of the)',
    iconSrc: '/assets/flags/cg.svg',
  },
  {
    value: 'c43232dd-d5fe-48ed-a069-55a104707047',
    label: 'Cook Islands',
    iconSrc: '/assets/flags/ck.svg',
  },
  {
    value: '7ba79faa-a64e-4600-b183-3360ac336837',
    label: 'Costa Rica',
    iconSrc: '/assets/flags/cr.svg',
  },
  {
    value: '9f037127-da3a-4da1-b59c-cdcca58ac248',
    label: "Cote d'Ivoire",
    iconSrc: '/assets/flags/ci.svg',
  },
  {
    value: 'eddbd0a9-1bfb-4831-8b66-5865fed49ee7',
    label: 'Croatia',
    iconSrc: '/assets/flags/hr.svg',
  },
  {
    value: 'b0e4e716-fee8-4e96-b916-543b4455da97',
    label: 'Cuba',
    iconSrc: '/assets/flags/cu.svg',
  },
  {
    value: '5d0ff7e2-d2d5-4fe2-a288-2de88d7edd87',
    label: 'Curacao',
    iconSrc: '/assets/flags/cw.svg',
  },
  {
    value: 'b311d256-edc3-47b7-b4e8-f11353596495',
    label: 'Cyprus',
    iconSrc: '/assets/flags/cy.svg',
  },
  {
    value: 'cdbbbc04-7d5e-41be-aa57-578b73d8db1f',
    label: 'Czech Republic',
    iconSrc: '/assets/flags/cz.svg',
  },
  {
    value: 'b9e9652b-8a6d-4b73-99ba-c0eba07c8292',
    label: 'Denmark',
    iconSrc: '/assets/flags/dk.svg',
  },
  {
    value: '305801c4-4388-486f-8161-4127caef7b3a',
    label: 'Djibouti',
    iconSrc: '/assets/flags/dj.svg',
  },
  {
    value: '52ae6901-1de3-4127-ace0-42ccfba1ff77',
    label: 'Dominica',
    iconSrc: '/assets/flags/dm.svg',
  },
  {
    value: '959cd108-dc88-4edf-98e4-9f69ea293a1f',
    label: 'Dominican Republic',
    iconSrc: '/assets/flags/do.svg',
  },
  {
    value: '23d39506-674e-4abc-8ae9-12f79ab50ffa',
    label: 'Ecuador',
    iconSrc: '/assets/flags/ec.svg',
  },
  {
    value: '91478b1f-33fa-47ef-97e4-25d7262fbf84',
    label: 'Egypt',
    iconSrc: '/assets/flags/eg.svg',
  },
  {
    value: '17d2580e-d7b1-474f-b31a-19b5b1780ad6',
    label: 'El Salvador',
    iconSrc: '/assets/flags/sv.svg',
  },
  {
    value: '1013d732-12b0-4979-8b7a-cdefe76b1d3d',
    label: 'Equatorial Guinea',
    iconSrc: '/assets/flags/gq.svg',
  },
  {
    value: '960b8808-60ad-40b8-a696-da45488143c0',
    label: 'Eritrea',
    iconSrc: '/assets/flags/er.svg',
  },
  {
    value: '43e92b8c-4731-474b-b9c3-edd21d03dc9b',
    label: 'Estonia',
    iconSrc: '/assets/flags/ee.svg',
  },
  {
    value: 'a282380a-f250-4afa-816a-c4ee8ecb1fb0',
    label: 'Ethiopia',
    iconSrc: '/assets/flags/et.svg',
  },
  {
    value: '5e935d96-03e5-4b17-b30b-e6897b85615a',
    label: 'Falkland Islands (Malvinas)',
    iconSrc: '/assets/flags/fk.svg',
  },
  {
    value: 'b321b9b7-a12f-49cd-8bfb-47eaefe9ce11',
    label: 'Faroe Islands',
    iconSrc: '/assets/flags/fo.svg',
  },
  {
    value: '46654ad7-1890-4be7-b973-8ec302f12b96',
    label: 'Fiji',
    iconSrc: '/assets/flags/fj.svg',
  },
  {
    value: '4de60187-a8f3-4367-a9dd-05f6a1098c71',
    label: 'Finland',
    iconSrc: '/assets/flags/fi.svg',
  },
  {
    value: '81e0427a-c22b-4689-90e3-d311521c5a7b',
    label: 'France',
    iconSrc: '/assets/flags/fr.svg',
  },
  {
    value: 'e260b0a8-745e-48db-be0c-bb5890166c37',
    label: 'French Guiana',
    iconSrc: '/assets/flags/gf.svg',
  },
  {
    value: '763c2286-3e77-4f8b-b17c-0041e9300b0e',
    label: 'French Polynesia',
    iconSrc: '/assets/flags/pf.svg',
  },
  {
    value: 'fc70f988-5821-40af-8271-1fd56e1a00be',
    label: 'French Southern Territories',
    iconSrc: '/assets/flags/tf.svg',
  },
  {
    value: 'ae9f0d84-70c1-425f-807b-7f72beb3d5a5',
    label: 'Gabon',
    iconSrc: '/assets/flags/ga.svg',
  },
  {
    value: '3e190a75-ec80-4804-bd75-924051ac3502',
    label: 'Gambia',
    iconSrc: '/assets/flags/gm.svg',
  },
  {
    value: 'b6a873bb-ab86-4b83-b604-0dfe0dd497e8',
    label: 'Georgia',
    iconSrc: '/assets/flags/ge.svg',
  },
  {
    value: '7b4798b7-8f30-46a1-81e9-51e2982f05f4',
    label: 'Germany',
    iconSrc: '/assets/flags/de.svg',
  },
  {
    value: '2558f1b0-ba5a-47f3-93b9-a44bb3175048',
    label: 'Ghana',
    iconSrc: '/assets/flags/gh.svg',
  },
  {
    value: '05c8e69f-1330-433e-a3d0-0226d74f3738',
    label: 'Gibraltar',
    iconSrc: '/assets/flags/gi.svg',
  },
  {
    value: '4b212290-c6d1-4abd-9bf7-38eef2e5f005',
    label: 'Greece',
    iconSrc: '/assets/flags/gr.svg',
  },
  {
    value: '24532a8c-a286-405b-973e-5f4fa867b2a2',
    label: 'Greenland',
    iconSrc: '/assets/flags/gl.svg',
  },
  {
    value: '274d8aa6-6e41-416a-bb04-d3872f2b74ee',
    label: 'Grenada',
    iconSrc: '/assets/flags/gd.svg',
  },
  {
    value: 'cf78c1c3-0eeb-4d4e-a29a-77c5da4e5b48',
    label: 'Guadeloupe',
    iconSrc: '/assets/flags/gp.svg',
  },
  {
    value: 'e70cb84a-c3d5-4757-b860-0ea649882384',
    label: 'Guam',
    iconSrc: '/assets/flags/gu.svg',
  },
  {
    value: '6d57a33e-79f6-49aa-85a7-d2265f4a5fc7',
    label: 'Guatemala',
    iconSrc: '/assets/flags/gt.svg',
  },
  {
    value: '50f78041-cbad-43f6-8562-3539bb2e55cc',
    label: 'Guernsey',
    iconSrc: '/assets/flags/gg.svg',
  },
  {
    value: 'a0a14e65-e242-4d2c-91e7-c29f4ec74bbe',
    label: 'Guinea',
    iconSrc: '/assets/flags/gn.svg',
  },
  {
    value: 'b3ac3ad0-50b6-47ef-b760-0cb4e088350f',
    label: 'Guinea-Bissau',
    iconSrc: '/assets/flags/gw.svg',
  },
  {
    value: 'd933b523-552e-44da-a059-8e448bf0ecb0',
    label: 'Guyana',
    iconSrc: '/assets/flags/gy.svg',
  },
  {
    value: '19392626-eb3a-48cc-b5fb-c78a521f9938',
    label: 'Haiti',
    iconSrc: '/assets/flags/ht.svg',
  },
  {
    value: '5570189b-0b30-4b34-84a7-21d5df398bca',
    label: 'Heard Island and McDonald Islands',
    iconSrc: '/assets/flags/hm.svg',
  },
  {
    value: 'b0920e1f-06d7-4a84-98ef-cced3ed75144',
    label: 'Honduras',
    iconSrc: '/assets/flags/hn.svg',
  },
  {
    value: '04aad6db-a85a-464d-b12d-94d9d0f7abc6',
    label: 'Hong Kong',
    iconSrc: '/assets/flags/hk.svg',
  },
  {
    value: '4309af89-f993-4999-be7a-abaf7a7625a7',
    label: 'Hungary',
    iconSrc: '/assets/flags/hu.svg',
  },
  {
    value: '56652efb-76de-46ab-88fc-4d68b2106533',
    label: 'Iceland',
    iconSrc: '/assets/flags/is.svg',
  },
  {
    value: '89a91d55-1a94-4c3b-a784-453d2c3307b6',
    label: 'India',
    iconSrc: '/assets/flags/in.svg',
  },
  {
    value: '1ecab360-ee83-46da-a869-fec0e0608d22',
    label: 'Indonesia',
    iconSrc: '/assets/flags/id.svg',
  },
  {
    value: 'bfeb1816-b57e-40e9-b7c9-1a36c2c4ee62',
    label: 'Iran',
    iconSrc: '/assets/flags/ir.svg',
  },
  {
    value: '3667bfd7-67a6-4ad5-ad19-1077678b9a0f',
    label: 'Iraq',
    iconSrc: '/assets/flags/iq.svg',
  },
  {
    value: 'ba259d43-2846-4de9-b59c-807dfba1e196',
    label: 'Ireland',
    iconSrc: '/assets/flags/ie.svg',
  },
  {
    value: 'bdad1793-3fda-4774-bafd-8e7d123bfdb4',
    label: 'Isle of Man',
    iconSrc: '/assets/flags/im.svg',
  },
  {
    value: '1d45a775-18dd-46ae-a506-4565044d4d15',
    label: 'Israel',
    iconSrc: '/assets/flags/il.svg',
  },
  {
    value: 'e231529e-b712-43cb-a6f0-f2b91092344d',
    label: 'Italy',
    iconSrc: '/assets/flags/it.svg',
  },
  {
    value: '35ba4b2a-1691-469b-a4f6-02a9efeafe27',
    label: 'Jamaica',
    iconSrc: '/assets/flags/jm.svg',
  },
  {
    value: '3de8cb4d-7591-4ccc-945c-d3df3335e178',
    label: 'Japan',
    iconSrc: '/assets/flags/jp.svg',
  },
  {
    value: '23dd2db9-525a-4215-9bf5-ac2c7a3c76af',
    label: 'Jersey',
    iconSrc: '/assets/flags/je.svg',
  },
  {
    value: '1f921a9b-a1fe-4866-9126-041d43aecfdc',
    label: 'Jordan',
    iconSrc: '/assets/flags/jo.svg',
  },
  {
    value: 'c87e88aa-598b-42e7-8f89-c7b94039dcfd',
    label: 'Kazakhstan',
    iconSrc: '/assets/flags/kz.svg',
  },
  {
    value: '353e9de9-799e-4d43-be64-3a08ccc3e5dd',
    label: 'Kenya',
    iconSrc: '/assets/flags/ke.svg',
  },
  {
    value: '35e3e08e-fe35-4218-8141-9c803940207f',
    label: 'Kiribati',
    iconSrc: '/assets/flags/ki.svg',
  },
  {
    value: '0d2cefa0-4215-4e52-8023-662878c3500a',
    label: "Korea (Democratic People's Republic of)",
    iconSrc: '/assets/flags/kp.svg',
  },
  {
    value: '1ba4ab09-ee48-4d25-b68d-6166f26c3e23',
    label: 'Korea (Republic of)',
    iconSrc: '/assets/flags/kr.svg',
  },
  {
    value: 'efae6a4e-dcf9-4a6a-ae00-cd52ee09b2d5',
    label: 'Kuwait',
    iconSrc: '/assets/flags/kw.svg',
  },
  {
    value: 'e3086e8d-2b3a-46f2-97fc-85f3390c3f2d',
    label: 'Kyrgyzstan',
    iconSrc: '/assets/flags/kg.svg',
  },
  {
    value: 'ce98e034-eebe-4d5e-9b37-30763df1879e',
    label: "Lao People's Democratic Republic",
    iconSrc: '/assets/flags/la.svg',
  },
  {
    value: '63227b88-7b5f-44c3-b2f2-b9b7a84eab80',
    label: 'Latvia',
    iconSrc: '/assets/flags/lv.svg',
  },
  {
    value: 'c3e829bf-d0fc-4585-b387-55923e54eedf',
    label: 'Lebanon',
    iconSrc: '/assets/flags/lb.svg',
  },
  {
    value: '0744c494-52a8-41cd-bb7d-21f23e436453',
    label: 'Lesotho',
    iconSrc: '/assets/flags/ls.svg',
  },
  {
    value: 'db87a5ce-f55b-465b-ad90-790f64c08b9f',
    label: 'Liberia',
    iconSrc: '/assets/flags/lr.svg',
  },
  {
    value: 'b4c3670d-ceca-4809-b728-843a5766c55b',
    label: 'Libya',
    iconSrc: '/assets/flags/ly.svg',
  },
  {
    value: '6b95a0f6-60ea-4691-8b90-4d21e64f5e47',
    label: 'Liechtenstein',
    iconSrc: '/assets/flags/li.svg',
  },
  {
    value: 'ea79198e-d3c1-454e-af7b-6dbbc128c282',
    label: 'Lithuania',
    iconSrc: '/assets/flags/lt.svg',
  },
  {
    value: '4bc588ec-cd40-444d-8060-4bec422cddcb',
    label: 'Luxembourg',
    iconSrc: '/assets/flags/lu.svg',
  },
  {
    value: '7563321f-db62-417f-807c-277c902f1702',
    label: 'Macao',
    iconSrc: '/assets/flags/mo.svg',
  },
  {
    value: '959ddc0e-d2e2-46ff-b1c7-5d4a1b451792',
    label: 'Macedonia (the former Yugoslav Republic of)',
    iconSrc: '/assets/flags/mk.svg',
  },
  {
    value: '65f590fa-10f9-481d-8471-958b24625b31',
    label: 'Madagascar',
    iconSrc: '/assets/flags/mg.svg',
  },
  {
    value: '12e61a54-9b26-44a2-b666-3f962902ba1c',
    label: 'Malawi',
    iconSrc: '/assets/flags/mw.svg',
  },
  {
    value: '822a367b-4f98-4b08-9f3c-2fd2575e1195',
    label: 'Malaysia',
    iconSrc: '/assets/flags/my.svg',
  },
  {
    value: 'd6a779b4-6194-456b-a80b-88113a0bcbd4',
    label: 'Maldives',
    iconSrc: '/assets/flags/mv.svg',
  },
  {
    value: '23458f61-5b39-4d45-87d6-66c47bba48b3',
    label: 'Mali',
    iconSrc: '/assets/flags/ml.svg',
  },
  {
    value: '80f6e189-b20b-42e6-9f4d-95012380ba76',
    label: 'Malta',
    iconSrc: '/assets/flags/mt.svg',
  },
  {
    value: '36ac6e6a-b7f4-46fa-b61d-1264fb5611ca',
    label: 'Marshall Islands',
    iconSrc: '/assets/flags/mh.svg',
  },
  {
    value: '30864e15-e329-45f7-bef2-86da570a7ecf',
    label: 'Martinique',
    iconSrc: '/assets/flags/mq.svg',
  },
  {
    value: '715132d2-7dc5-4463-928a-e60f9d66a790',
    label: 'Mauritania',
    iconSrc: '/assets/flags/mr.svg',
  },
  {
    value: '704069f7-6e78-42ec-832e-ab26f62783f7',
    label: 'Mauritius',
    iconSrc: '/assets/flags/mu.svg',
  },
  {
    value: '5ec79d4b-57e8-4fe0-8a55-f131d5722da8',
    label: 'Mayotte',
    iconSrc: '/assets/flags/yt.svg',
  },
  {
    value: 'afa0bd7b-25f6-4d3b-a16b-1d13539d0e90',
    label: 'Mexico',
    iconSrc: '/assets/flags/mx.svg',
  },
  {
    value: '7e03dac0-5c51-45da-90ff-0eb4d1cd5212',
    label: 'Micronesia (Federated States of)',
    iconSrc: '/assets/flags/fm.svg',
  },
  {
    value: 'e9c066e6-2fb6-4ad4-8fb3-bd296c8390c4',
    label: 'Moldova (Republic of)',
    iconSrc: '/assets/flags/md.svg',
  },
  {
    value: '2684043c-ac97-4faf-9e39-7b3adf06d7aa',
    label: 'Monaco',
    iconSrc: '/assets/flags/mc.svg',
  },
  {
    value: '3c79df5f-b55c-4df2-af2a-d50b843d3022',
    label: 'Mongolia',
    iconSrc: '/assets/flags/mn.svg',
  },
  {
    value: '329993e1-fafb-4860-b513-b12bf83b9aa0',
    label: 'Montenegro',
    iconSrc: '/assets/flags/me.svg',
  },
  {
    value: 'f899e4a1-b210-45e6-8d91-17f30b907d54',
    label: 'Montserrat',
    iconSrc: '/assets/flags/ms.svg',
  },
  {
    value: 'fd9540ba-d86f-410d-9804-f0e06803ddac',
    label: 'Morocco',
    iconSrc: '/assets/flags/ma.svg',
  },
  {
    value: '2dccee07-4231-4a57-90b8-a451c14b2009',
    label: 'Mozambique',
    iconSrc: '/assets/flags/mz.svg',
  },
  {
    value: 'deda4ae9-14e6-4020-a8a9-a3fff4d3cbb1',
    label: 'Myanmar',
    iconSrc: '/assets/flags/mm.svg',
  },
  {
    value: '3e4f1fe9-8a4a-4973-a9ba-c54134bcbf9c',
    label: 'Namibia',
    iconSrc: '/assets/flags/na.svg',
  },
  {
    value: '58c4eaa6-03d9-4152-9979-5223ad398a0a',
    label: 'Nauru',
    iconSrc: '/assets/flags/nr.svg',
  },
  {
    value: '9a620c7a-6b34-4e53-90db-fbbc2daf6c31',
    label: 'Nepal',
    iconSrc: '/assets/flags/np.svg',
  },
  {
    value: '4722c4a2-3775-4d82-9ca2-d43572662f14',
    label: 'Netherlands',
    iconSrc: '/assets/flags/nl.svg',
  },
  {
    value: 'ec997eff-8e21-4f2e-85bf-e1496bff3a62',
    label: 'New Caledonia',
    iconSrc: '/assets/flags/nc.svg',
  },
  {
    value: '304c7fed-81ce-4296-8565-b2f677a00ded',
    label: 'New Zealand',
    iconSrc: '/assets/flags/nz.svg',
  },
  {
    value: '1ebeccbe-11d1-4ca4-8547-aebf599d6f81',
    label: 'Nicaragua',
    iconSrc: '/assets/flags/ni.svg',
  },
  {
    value: 'cbdc8904-419b-4aa7-ab09-8cdf7d2c78c0',
    label: 'Niger',
    iconSrc: '/assets/flags/ne.svg',
  },
  {
    value: '69c72ee2-3875-4952-9434-4bae4f464917',
    label: 'Nigeria',
    iconSrc: '/assets/flags/ng.svg',
  },
  {
    value: '1561f8a5-ba98-4bb4-b422-26697a9fa2e1',
    label: 'Niue',
    iconSrc: '/assets/flags/nu.svg',
  },
  {
    value: '24700a64-33c4-42de-a20d-cb282f82aa3d',
    label: 'Norfolk Island',
    iconSrc: '/assets/flags/nf.svg',
  },
  {
    value: '346558c8-5226-45ee-b7d5-8b8e9e1727bb',
    label: 'Northern Mariana Islands',
    iconSrc: '/assets/flags/mp.svg',
  },
  {
    value: '5b2e8a7d-480e-4445-b118-9c1b1bf500ef',
    label: 'Norway',
    iconSrc: '/assets/flags/no.svg',
  },
  {
    value: '88bbb472-3109-4031-924d-cbe45a71d97f',
    label: 'Oman',
    iconSrc: '/assets/flags/om.svg',
  },
  {
    value: 'b3b04965-71a1-4519-a2f1-a9772c4e512c',
    label: 'Pakistan',
    iconSrc: '/assets/flags/pk.svg',
  },
  {
    value: 'bb37f482-636f-439d-b0fd-36ddd80ee979',
    label: 'Palau',
    iconSrc: '/assets/flags/pw.svg',
  },
  {
    value: '10650a88-454b-431c-87bf-511a20af2be9',
    label: 'Palestine, State of',
    iconSrc: '/assets/flags/ps.svg',
  },
  {
    value: 'c875e33f-955e-41cc-a5ed-727707a6e865',
    label: 'Panama',
    iconSrc: '/assets/flags/pa.svg',
  },
  {
    value: '097d0a07-ba8c-44d7-a3c8-5d7fbf20ab06',
    label: 'Papua New Guinea',
    iconSrc: '/assets/flags/pg.svg',
  },
  {
    value: '582d7cb9-54d7-4087-a685-7bf33c8b6f64',
    label: 'Paraguay',
    iconSrc: '/assets/flags/py.svg',
  },
  {
    value: '5f7ba9b0-2dfe-42d8-a1f6-2413b8aca387',
    label: 'Peru',
    iconSrc: '/assets/flags/pe.svg',
  },
  {
    value: '40776c66-c093-47b0-8be2-0d4de6edacdf',
    label: 'Philippines',
    iconSrc: '/assets/flags/ph.svg',
  },
  {
    value: '0cbd75c6-3473-430e-95bf-b6d682880a7f',
    label: 'Pitcairn',
    iconSrc: '/assets/flags/pn.svg',
  },
  {
    value: '9aedeeaf-ed9e-4b9f-b05f-5394a5eb93e5',
    label: 'Poland',
    iconSrc: '/assets/flags/pl.svg',
  },
  {
    value: 'ae9ea696-38cc-44b4-b11c-389d093526d9',
    label: 'Portugal',
    iconSrc: '/assets/flags/pt.svg',
  },
  {
    value: 'd6175b9a-fee0-402a-a525-2a73eac56ce7',
    label: 'Puerto Rico',
    iconSrc: '/assets/flags/pr.svg',
  },
  {
    value: 'aeb9d2ec-10b2-4323-a02b-a204cdc072c0',
    label: 'Qatar',
    iconSrc: '/assets/flags/qa.svg',
  },
  {
    value: 'd1e54ca2-dbea-443c-9737-51190d157ac2',
    label: 'Reunion',
    iconSrc: '/assets/flags/re.svg',
  },
  {
    value: '9ccfa455-b38b-41df-b80e-68e6fb10b67e',
    label: 'Romania',
    iconSrc: '/assets/flags/ro.svg',
  },
  {
    value: '14c5fb5e-3a57-493a-8298-5fcb6f85bce3',
    label: 'Russian Federation',
    iconSrc: '/assets/flags/ru.svg',
  },
  {
    value: 'a4b126df-1bad-4e2f-8d83-dbf1cf88ecff',
    label: 'Rwanda',
    iconSrc: '/assets/flags/rw.svg',
  },
  {
    value: 'a4ac1a41-941f-4aec-a4d0-c67c9051a940',
    label: 'Saint Barthelemy',
    iconSrc: '/assets/flags/bl.svg',
  },
  {
    value: '27ffd2d6-b9fc-4294-a7ab-ece5e742a15b',
    label: 'Saint Helena, Ascension and Tristan da Cunha',
    iconSrc: '/assets/flags/sh.svg',
  },
  {
    value: '9c60aa0e-41fa-4bf4-b165-79aa39d87f20',
    label: 'Saint Kitts and Nevis',
    iconSrc: '/assets/flags/kn.svg',
  },
  {
    value: '63192d36-cd39-46a2-9a73-beee17e9cfa7',
    label: 'Saint Lucia',
    iconSrc: '/assets/flags/lc.svg',
  },
  {
    value: '20052b93-5feb-4cf8-a923-bc41079e28e5',
    label: 'Saint Martin (French part)',
    iconSrc: '/assets/flags/mf.svg',
  },
  {
    value: '798e4738-99c7-497f-9c60-7982a8e2852a',
    label: 'Saint Pierre and Miquelon',
    iconSrc: '/assets/flags/pm.svg',
  },
  {
    value: '546bbb02-39a8-4536-83b1-70af58b3c3f7',
    label: 'Saint Vincent and the Grenadines',
    iconSrc: '/assets/flags/vc.svg',
  },
  {
    value: '2748b358-5a80-4146-a705-35928a019288',
    label: 'Samoa',
    iconSrc: '/assets/flags/ws.svg',
  },
  {
    value: 'ef3b4ff9-0131-4646-b87c-ba7a1d9303e9',
    label: 'San Marino',
    iconSrc: '/assets/flags/sm.svg',
  },
  {
    value: 'cb201cde-b521-418b-bbeb-3bd173d9e8bf',
    label: 'Sao Tome and Principe',
    iconSrc: '/assets/flags/st.svg',
  },
  {
    value: 'b6aa1f76-bb7b-49c0-980d-f27e043cfd3e',
    label: 'Saudi Arabia',
    iconSrc: '/assets/flags/sa.svg',
  },
  {
    value: '63a1087d-5cb6-4103-b865-a4d29afb7dae',
    label: 'Senegal',
    iconSrc: '/assets/flags/sn.svg',
  },
  {
    value: 'f4ddd7d8-4b1f-4129-9c45-e43fabe33923',
    label: 'Serbia',
    iconSrc: '/assets/flags/rs.svg',
  },
  {
    value: '2d69fd57-a7bb-410c-8f69-37d96d373aaf',
    label: 'Seychelles',
    iconSrc: '/assets/flags/sc.svg',
  },
  {
    value: 'b1b8ecac-eddf-4060-bbaa-26cfde18d0f6',
    label: 'Sierra Leone',
    iconSrc: '/assets/flags/sl.svg',
  },
  {
    value: 'db4b868b-8a45-4b19-b744-a6c5813c6971',
    label: 'Singapore',
    iconSrc: '/assets/flags/sg.svg',
  },
  {
    value: '5768714a-40db-468a-9f11-70ac8a08fd05',
    label: 'Sint Maarten (Dutch part)',
    iconSrc: '/assets/flags/sx.svg',
  },
  {
    value: '2443aed8-5a8c-4990-aeab-35828ab2096d',
    label: 'Slovakia',
    iconSrc: '/assets/flags/sk.svg',
  },
  {
    value: '37517d30-c20a-40ce-a2f4-314b769674f4',
    label: 'Slovenia',
    iconSrc: '/assets/flags/si.svg',
  },
  {
    value: '743845da-5295-4c67-8812-fa7874dea69e',
    label: 'Solomon Islands',
    iconSrc: '/assets/flags/sb.svg',
  },
  {
    value: '90396a5b-b55b-4f93-8b4f-7772f79041ae',
    label: 'Somalia',
    iconSrc: '/assets/flags/so.svg',
  },
  {
    value: '58a055b9-dff3-42b9-a36b-a7b30f229870',
    label: 'South Africa',
    iconSrc: '/assets/flags/za.svg',
  },
  {
    value: 'af063f60-50af-4453-b3ad-3c913dcc2d57',
    label: 'South Georgia and the South Sandwich Islands',
    iconSrc: '/assets/flags/gs.svg',
  },
  {
    value: 'e05ead32-f041-41ec-b05d-8b2aef030812',
    label: 'South Sudan',
    iconSrc: '/assets/flags/ss.svg',
  },
  {
    value: '012c00b9-28ab-447d-a6f8-13085efc8303',
    label: 'Spain',
    iconSrc: '/assets/flags/es.svg',
  },
  {
    value: 'c2b55a08-1635-42bf-87ab-5fa97a5c24a2',
    label: 'Sri Lanka',
    iconSrc: '/assets/flags/lk.svg',
  },
  {
    value: '6e5bf329-a236-4252-8b44-56148e3dcbb2',
    label: 'Sudan',
    iconSrc: '/assets/flags/sd.svg',
  },
  {
    value: '2777145c-165a-472f-af2f-3137f698a83e',
    label: 'Suriname',
    iconSrc: '/assets/flags/sr.svg',
  },
  {
    value: '003daa62-84bf-4065-bc94-f2c8e0a9bd05',
    label: 'Svalbard and Jan Mayen',
    iconSrc: '/assets/flags/sj.svg',
  },
  {
    value: 'c0518fc1-ec3a-46d3-a9d0-f7688fa43840',
    label: 'Swaziland',
    iconSrc: '/assets/flags/sz.svg',
  },
  {
    value: 'e9bc4274-06c0-46e8-ad06-d4d81ea36d13',
    label: 'Sweden',
    iconSrc: '/assets/flags/se.svg',
  },
  {
    value: 'a1d80cae-7f10-4102-ba96-d1007794227c',
    label: 'Syrian Arab Republic',
    iconSrc: '/assets/flags/sy.svg',
  },
  {
    value: 'f3a32ff4-70fc-4bda-92a7-f70cd5eb8d65',
    label: 'Taiwan, Province of China',
    iconSrc: '/assets/flags/tw.svg',
  },
  {
    value: 'dbf38137-f337-41a9-a4b7-17fdcc7cf3d8',
    label: 'Tajikistan',
    iconSrc: '/assets/flags/tj.svg',
  },
  {
    value: '27f7143d-6fc8-4028-b3bb-cea04674d75d',
    label: 'Tanzania, United Republic of',
    iconSrc: '/assets/flags/tz.svg',
  },
  {
    value: '30473a6d-4466-46d4-a88f-a617a79adcde',
    label: 'Thailand',
    iconSrc: '/assets/flags/th.svg',
  },
  {
    value: '6e2f4fb9-153b-4271-a5af-282e019ead26',
    label: 'Timor-Leste',
    iconSrc: '/assets/flags/tl.svg',
  },
  {
    value: '7cba59a4-c54c-44ab-b3c6-46af2d64d8ef',
    label: 'Togo',
    iconSrc: '/assets/flags/tg.svg',
  },
  {
    value: 'b88d460d-dfed-43e6-a903-bb955d75a5de',
    label: 'Tokelau',
    iconSrc: '/assets/flags/tk.svg',
  },
  {
    value: '1691686e-535f-48ae-b285-731349bd8635',
    label: 'Tonga',
    iconSrc: '/assets/flags/to.svg',
  },
  {
    value: 'b8b7324f-5c24-4baf-a6c6-4d52c06df6f8',
    label: 'Trinidad and Tobago',
    iconSrc: '/assets/flags/tt.svg',
  },
  {
    value: 'ef54224e-604f-404e-b745-b0c89c07312f',
    label: 'Tunisia',
    iconSrc: '/assets/flags/tn.svg',
  },
  {
    value: '50ec4591-5cbe-4ce2-97fb-900620ff6890',
    label: 'Turkey',
    iconSrc: '/assets/flags/tr.svg',
  },
  {
    value: '4b9d7894-6a20-4e97-a8fb-cee58eb66f88',
    label: 'Turkmenistan',
    iconSrc: '/assets/flags/tm.svg',
  },
  {
    value: '047e27a1-9934-4ba9-a155-1ee09ed6b15e',
    label: 'Turks and Caicos Islands',
    iconSrc: '/assets/flags/tc.svg',
  },
  {
    value: '6ad83527-4a9c-42be-9cf0-4e64b8ba2471',
    label: 'Tuvalu',
    iconSrc: '/assets/flags/tv.svg',
  },
  {
    value: 'ddbf43c5-a9dd-4981-b242-77508160111d',
    label: 'Uganda',
    iconSrc: '/assets/flags/ug.svg',
  },
  {
    value: 'cc4e1e47-376e-4d27-b762-15612fb4bfaa',
    label: 'Ukraine',
    iconSrc: '/assets/flags/ua.svg',
  },
  {
    value: 'ea52f837-c792-4022-b811-8d740cf25221',
    label: 'United Arab Emirates',
    iconSrc: '/assets/flags/ae.svg',
  },
  {
    value: '4f235e95-fc35-4321-bec4-6ba1495128b3',
    label: 'United Kingdom of Great Britain and Northern Ireland',
    iconSrc: '/assets/flags/gb.svg',
  },
  {
    value: '4c4fdbcf-e4e8-411e-8623-e57d7d2728aa',
    label: 'United States Minor Outlying Islands',
    iconSrc: '/assets/flags/um.svg',
  },
  {
    value: 'a7c322e7-f55a-4ee2-9af0-0c2c939176f6',
    label: 'United States of America',
    iconSrc: '/assets/flags/us.svg',
  },
  {
    value: '255f272a-1463-463a-a38c-b78cbe6c9688',
    label: 'Uruguay',
    iconSrc: '/assets/flags/uy.svg',
  },
  {
    value: '280c8497-24cb-4449-b457-b2bbadaad46b',
    label: 'Uzbekistan',
    iconSrc: '/assets/flags/uz.svg',
  },
  {
    value: '14cd06c1-2f23-43c6-a11d-04223c170cfc',
    label: 'Vanuatu',
    iconSrc: '/assets/flags/vu.svg',
  },
  {
    value: '2490115f-7ee4-4084-ae57-7d9b890168ba',
    label: 'Vatican City State',
    iconSrc: '/assets/flags/va.svg',
  },
  {
    value: 'c5d46e17-3bbd-43e8-a7d4-dc2df5d92f5d',
    label: 'Venezuela (Bolivarian Republic of)',
    iconSrc: '/assets/flags/ve.svg',
  },
  {
    value: '1b4ac287-e495-492f-9092-cd5f304b33be',
    label: 'Vietnam',
    iconSrc: '/assets/flags/vn.svg',
  },
  {
    value: '6923cea5-3702-492f-a708-d271fe11a066',
    label: 'Virgin Islands (British)',
    iconSrc: '/assets/flags/vg.svg',
  },
  {
    value: '303c7ed7-5571-4067-b2ec-da902d6717f8',
    label: 'Virgin Islands (U.S.)',
    iconSrc: '/assets/flags/vi.svg',
  },
  {
    value: '0b15b463-5eba-4a9d-99f9-19e0ae6e0bf4',
    label: 'Wallis and Futuna',
    iconSrc: '/assets/flags/wf.svg',
  },
  {
    value: 'fb829341-6885-43cc-860a-7e8df2ba68da',
    label: 'Western Sahara',
    iconSrc: '/assets/flags/eh.svg',
  },
  {
    value: 'f522c2f2-7f25-4d54-a714-5a76e7779488',
    label: 'Yemen',
    iconSrc: '/assets/flags/ye.svg',
  },
  {
    value: '6f4ebe5e-2048-4fca-92ef-e51c26360e15',
    label: 'Zambia',
    iconSrc: '/assets/flags/zm.svg',
  },
  {
    value: 'cc073652-982d-4aca-9133-7e44f0dc5e34',
    label: 'Zimbabwe',
    iconSrc: '/assets/flags/zw.svg',
  },
];
