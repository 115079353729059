import styled from 'styled-components';

import { Padding, RemSize } from '../../../../theme/theme';

export const ButtonsContainer = styled.div<{ $addPadding?: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: auto;
  padding: ${({ $addPadding }) => $addPadding && '1rem'};
`;

export const InputItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
`;

export const InputWrapper = styled.div`
  width: 100%;
  height: 3.5rem;
  padding: 0 1rem;
`;

export const RegularInputContainer = styled.div`
  width: 100%;
  margin-bottom: 1.25rem;
  height: 3.5rem;
`;

export const ItemsContainer = styled.div<{ $disableOverflow?: boolean; $maxHeight?: RemSize | 'auto' }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 0.5rem;
  overflow-y: ${({ $disableOverflow }) => $disableOverflow && 'auto'};
  margin-left: 1rem;
  margin-top: 1rem;
  max-height: ${({ $maxHeight }) => $maxHeight};
`;

export const StyledElementContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledElementWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
`;

export const StyledIcon = styled.img`
  width: 2rem;
  height: 1.5rem;
`;

export const ItemsSelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ItemLabelWrapper = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const ItemWrapper = styled.button<{
  $isSelected: boolean;
  $isChoosen: boolean;
  $padding?: Padding;
}>`
  padding: ${({ $padding }) => $padding || '1rem'};
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.v2.border.primary};
  height: 3.5rem;
  margin-right: 1rem;
  border-radius: ${({ theme }) => theme.v2.componentsRadius.cards.medium};

  &:active {
    background-color: ${({ theme }) => theme.v2.surface.informationLight};
    border: 1px solid ${({ theme }) => theme.v2.border.information};
  }

  ${({ $isSelected, theme }) =>
    $isSelected &&
    `
      background-color: ${theme.v2.surface.informationLight};
      border-color: ${theme.v2.border.information};

     ${ItemLabelWrapper} {
        span {  
          font-weight: ${theme.typography.weights.bold};
          color: ${theme.v2.text.information};
        }

    `}
  ${({ $isChoosen, theme }) =>
    $isChoosen
      ? `
        background-color: ${theme.v2.surface.informationLight};
        border-color: ${theme.v2.border.information};

        ${ItemLabelWrapper} {
        span {  
          font-weight: ${theme.typography.weights.bold};
          color: ${theme.v2.text.information};
        }
      `
      : ''}
`;

export const TitleWrapper = styled.div`
  text-align: center;
  padding: 1.5rem 1.5rem 0 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const ItemSelectionContent = styled.div`
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 1.25rem;
  margin-top: 0.5rem;
  padding-top: 0.5rem;
`;

export const TooltipWrapper = styled.div`
  width: max-content;
`;
