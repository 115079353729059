import styled from 'styled-components';

export const PostImage = styled.img`
  width: 100%;
  height: 8rem;
  object-fit: cover;
`;

export const TextGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  padding-top: 1.5rem;
`;

export const TextOverflowWrapper = styled.div`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin: 0.75rem;
`;
