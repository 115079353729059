import styled from 'styled-components';

export const BlogLatestContainer = styled.div<{ $thumbnailImage: string }>`
  width: 100%;
  height: 16.5rem;
  border-radius: ${({ theme }) => theme.v2.radius.small};
  background-image: url('${({ $thumbnailImage }) => $thumbnailImage}');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  cursor: pointer;
  display: flex;
  align-items: flex-end;
  overflow: hidden;
`;

export const InfoContainer = styled.div`
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
`;
