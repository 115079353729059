import { TenantPermissions } from '../../../../../enums/tenantPermissions';

export const getPortfolioOverviewEmptyStateText = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  permissions: Record<string, any>,
  textTranslation: (key: string, prop?: {}) => string
) => {
  const smartCollectibles = (permissions[TenantPermissions.NFT] as boolean)
    ? textTranslation('global.smartCollectibles')
    : '';
  const smartShares = (permissions[TenantPermissions.SHARE] as boolean) ? textTranslation('global.smartShares') : '';
  const smartBonds = (permissions[TenantPermissions.BOND] as boolean) ? textTranslation('global.smartBonds') : '';

  const grantedPermissions = [smartCollectibles, smartShares, smartBonds].filter(Boolean);

  const permissionText =
    grantedPermissions.length > 1
      ? grantedPermissions.slice(0, -1).join(', ') +
        ` ${textTranslation('global.and')} ` +
        grantedPermissions[grantedPermissions.length - 1]
      : grantedPermissions[0];

  return `${textTranslation('global.youHaveNo')} ${permissionText} ${textTranslation('global.atTheMoment')}.`;
};
