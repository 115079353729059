import styled from 'styled-components';

import { RemSize } from '../../../../../theme';

export const IconHolder = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: transparent;
`;

export const TooltipHolder = styled.div<{ $height: RemSize }>`
  width: 100%;
  height: ${({ $height }) => $height};
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TooltipContent = styled.div<{ $width?: RemSize | string }>`
  width: ${({ $width }) => $width};
  height: 1.5rem;
  border-radius: 0.5rem;
  padding: 1.2rem 0.75rem;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.v2.surface.tertiary};
`;

export const TipWrapper = styled.div<{ $leftPosition?: string; $width?: RemSize | string }>`
  position: absolute;
  top: 1.7rem;
  width: ${({ $width }) => $width};
  justify-content: center;
  display: flex;
  left: ${({ $leftPosition }) => $leftPosition};
  transform: translateX(-50%);
`;

export const Wrapper = styled.div`
  display: flex;
  gap: 0.25rem;
  width: 100%;
  align-items: flex-end;
  justify-content: flex-end;
  position: relative;
`;
