import styled, { css } from 'styled-components';

import { TextTemplate } from '../../../shared/typography/TextTemplate.styles.tsx';

export const DocumentContainer = styled.div<{ $backgroundColor: 'disabled' | 'primary'; $isHoverAllowed: boolean }>`
  width: 100%;
  padding: 1rem;
  background-color: ${({ theme, $backgroundColor }) => theme.v2.surface[$backgroundColor]};
  border: 1px solid ${({ theme }) => theme.v2.border.primary};
  border-radius: ${({ theme }) => theme.v2.componentsRadius.cards.large};
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: ${({ $isHoverAllowed }) => ($isHoverAllowed ? 'pointer' : 'auto')};

  &:hover {
    ${({ $isHoverAllowed }) =>
      $isHoverAllowed &&
      css`
        box-shadow: 0px 0px 16px 0px #00000005;
        box-shadow: 0px 1px 0px 0px #00000005;
        box-shadow: 0px 10px 24px 0px #00000005;
      `}
  }

  &:active {
    box-shadow: none;
  }
`;

export const LeftSideContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const TextsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${TextTemplate} {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
`;

export const DocumentStateWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;

export const IconWrapper = styled.div`
  mix-width: 2.5rem;
`;
