import { useTheme } from 'styled-components';

import { CountryIconV2, CountryName } from '../../../v2';
import { Text } from '../text';

import { Container, Status, Tab } from './versionTabs.styles';

export type VersionType = {
  name: string;
  status: 'pending' | 'live' | 'error';
  active: boolean;
  id: string;
  flag?: string;
};

type VersionTabsProps = {
  versions: VersionType[];
  handleClickVersion: (version: VersionType) => void;
};

export const VersionTabs = ({ versions, handleClickVersion }: VersionTabsProps) => {
  const theme = useTheme();
  const isMultipleVersions = versions.length > 1;
  return (
    <Container>
      {versions.map((version) => {
        return (
          <Tab
            key={version.name}
            $isMultipleTabs={isMultipleVersions}
            $isActive={version.active}
            onClick={() => handleClickVersion(version)}
          >
            <CountryIconV2
              name={version.flag as CountryName}
              width={'1.625rem'}
              height={'1.25rem'}
              borderRadius={'0.25rem'}
            />
            <Text color={theme.v2.text.headingPrimary} fontWeight={'bold'}>
              {version.name}
            </Text>
            {isMultipleVersions && <Status $status={version.status} />}
          </Tab>
        );
      })}
    </Container>
  );
};
