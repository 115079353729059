import { motion } from 'framer-motion';
import styled from 'styled-components';

import { RemSize } from '../../../theme';

import { SkeletonStyleProps } from './types';

export const Container = styled.div<SkeletonStyleProps>`
  border-radius: ${({ theme, $variation }) =>
    $variation === 'circle' ? theme.v2.radius.full : theme.v2.radius.smaller};
  background: ${({ theme }) =>
    theme.v2.other.type !== 'light' ? theme.v2.colors.neutral25 : theme.v2.colors.neutral400};
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
`;

export const GradientContainer = styled(motion.div)<{ $gradientWidth?: RemSize }>`
  position: absolute;
  background: ${({ theme }) =>
    theme.v2.other.type !== 'light'
      ? `linear-gradient(270deg, #e8e9f1 0%, #ffffff 34.67%, #ffffff 64.5%, #e8e9f1 100%)`
      : `linear-gradient(270deg, #262837 0%, #383B4D 34.67%, #383B4D 64.5%, #262837 100%)`};
  opacity: 0.4;
  width: ${({ $gradientWidth }) => $gradientWidth};
  height: 100%;
`;
