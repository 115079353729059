import { BarChartIcon, FolderOpenIcon, HomeIcon, MultipleFileIcon, MessageTextIcon } from '@metaswiss/ui-kit';

import { TenantPermissions } from '../../../../../enums/tenantPermissions';
import { routes } from '../../../../../router/routes';
import { SideBarItemProps } from '../../../../../shared/types/sidebarItemProps';

export const sidebarItems: SideBarItemProps[] = [
  {
    label: 'Home',
    icon: HomeIcon,
    value: 'home',
    route: routes.home.root,
  },
  {
    label: 'Offering',
    icon: BarChartIcon,
    value: 'offering',
    route: [
      { route: routes.offering.smartCollectibles.root, permission: TenantPermissions.NFT },
      { route: routes.offering.smartShares.root, permission: TenantPermissions.SHARE },
      { route: routes.offering.smartBonds.root, permission: TenantPermissions.BOND },
    ],
  },
  {
    label: 'Cases',
    icon: FolderOpenIcon,
    value: 'cases',
    route: routes.cases.active.root,
  },
  {
    label: 'Portfolio',
    icon: MultipleFileIcon,
    value: 'portfolio',
    route: routes.portfolio.overview,
  },
  {
    label: 'Blog',
    icon: MessageTextIcon,
    value: 'blog',
    route: routes.blog.root,
  },
];
