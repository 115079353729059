import { RemSize } from '@metaswiss/ui-kit';
import styled from 'styled-components';

export const WrapperImage = styled.div<{ $imgHeight?: RemSize }>`
  height: ${({ $imgHeight }) => ($imgHeight ? $imgHeight : '8rem')};
  overflow: hidden;
  border-radius: ${({ theme }) =>
    `${theme.v2.componentsRadius.cards.large} ${theme.v2.componentsRadius.cards.large} 0 0`};
`;

export const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const DescriptionTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.25rem;
  margin-top: 0.5rem;
  width: 100%;
`;

export const DescriptionWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: pre-wrap;
  height: 2.25rem;
`;

export const BlogItemContent = styled.div<{ $contentHeight?: RemSize }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.75rem;
  height: ${({ $contentHeight }) => ($contentHeight ? $contentHeight : 'auto')};
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

export const TitleWrapper = styled.div`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const DateWrapper = styled.div`
  flex-shrink: 0;
`;

export const ButtonWrapper = styled.div`
  margin: 1rem;
`;
